import React from 'react';
import {
  styled,
  LAYOUT,
  POSITION,
  FLEX_CONTAINER,
} from '@mentimeter/ragnar-styled';
import { cn } from '@mentimeter/ragnar-tailwind-config';
import { rule } from '../Box';

const FelaForm = styled('form', {
  displayName: 'Form',
})(
  rule,
  'kosmosBorderRadius',
  'kosmosSpacing',
  'color',
  ...LAYOUT,
  ...FLEX_CONTAINER,
  ...POSITION,
);

type FormT = React.ComponentProps<typeof FelaForm>;

export const Form = React.forwardRef((props: FormT, ref) => {
  const { className, ...rest } = props;

  const defaultClasses = [
    'r-form',
    'flex',
    'flex-col',
    'flex-none',
    'w-auto',
    'items-start',
    'min-w-0',
    'min-h-0',
    'relative',
  ];

  return (
    <FelaForm {...rest} ref={ref} className={cn(defaultClasses, className)} />
  );
});
