import { useEffect, useLayoutEffect as _useLayoutEffect } from 'react';

/**
 * useLayoutEffect throws a warning when present in a component rendered by the server.
 * To solve the problem while maintaining the hook linter rules, we substitute it for
 * useEffect on the server. This technique is used by for example redux.
 *
 * https://gist.github.com/gaearon/e7d97cdf38a2907924ea12e4ebdf3c85
 * https://medium.com/@alexandereardon/uselayouteffect-and-ssr-192986cdcf7a
 */
export const useLayoutEffect =
  typeof window === 'undefined' ? useEffect : _useLayoutEffect;
